import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import * as sections from '../components/sections';

interface PageProps {
  data: {
    page: {
      id: string;
      title: string;
      slug: string;
      description: string;
      image: { id: string; url: string };
      blocks: sections.HomepageBlock[];
    };
  };
}

export default function Page(props: PageProps): JSX.Element {
  const { page } = props.data;

  return (
    <Layout {...page}>
      {page.blocks.map((block) => {
        const { id, blocktype, ...componentProps } = block;
        const Component = sections[blocktype] || null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return <Component key={id} {...(componentProps as any)} />;
      })}
    </Layout>
  );
}

export const query = graphql`
  query PageContent($id: String!) {
    page(id: { eq: $id }) {
      id
      title
      slug
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...HeroContent
        ...SliderContent
        ...FeatureListContent
        ...CtaContent
        ...LogoListContent
        ...TestimonialListContent
        ...BenefitListContent
        ...StatListContent
        ...ProductListContent
        ...FormContent
      }
    }
  }
`;
